export const onServiceWorkerUpdateReady = () => {
  const answer = window.confirm(
    `Diese Webseite wurde aktualisiert. ` +
      `Möchten Sie die neueste Version nun laden?`
  )

  if (answer === true) {
    window.location.reload()
  }
}
